import { Box } from '@mui/material';
import { type EmblaCarouselType, type EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  dataFallbackGif,
  propertyFallbackImage
} from '../../../utils/Constants/fallbackImageUrl';
import {
  EmblaNextButton,
  EmblaPrevButton,
  usePrevNextButtons
} from '../EmblaArrowButtons';
import styles from './styles';

interface CarouselImage {
  id: string;
  url: string;
  title: string;
}

interface EmblaCarouselProps {
  options?: EmblaOptionsType;
  images: CarouselImage[];
  lazilyLoadFirstImage?: boolean;
}

const options = {
  loop: true
};

const slideBuffer = 1;

function EmblaImageCarousel({
  images,
  lazilyLoadFirstImage
}: EmblaCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { onPrevClick, onNextClick } = usePrevNextButtons(emblaApi);
  const [slidesInView, setSlidesInView] = useState<number[]>([0]);
  const imageLength = images.length;

  const updateSlidesInView = useCallback((embla: EmblaCarouselType) => {
    setSlidesInView((prev) => {
      if (prev.length === embla.slideNodes().length) {
        embla.off('slidesInView', updateSlidesInView);
      }

      const inView = embla
        .slidesInView()
        .filter((index) => !prev.includes(index));

      return prev.concat(inView);
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    updateSlidesInView(emblaApi);
    // have to pass a new arrow function here to make it works on map
    emblaApi.on('slidesInView', (e) => updateSlidesInView(e));
    emblaApi.on('reInit', (e) => updateSlidesInView(e));
  }, [emblaApi, updateSlidesInView]);

  return (
    <Box component="section" sx={styles.container}>
      <Box ref={emblaRef} sx={styles.scrollViewport}>
        <Box sx={styles.scrollContainer}>
          {images.map((image, index) => {
            const isInView = slidesInView.some(
              (slideIndex) =>
                Math.abs(slideIndex - index) <= slideBuffer ||
                Math.abs(slideIndex - index) >= imageLength - slideBuffer
            );

            return (
              <img
                key={image.id}
                style={styles.slide as CSSProperties}
                src={
                  !isInView
                    ? dataFallbackGif
                    : image.url || propertyFallbackImage
                }
                onError={(img) => {
                  const imgElement = img.currentTarget;

                  // eslint-disable-next-line
                  imgElement.onerror = null;
                  // eslint-disable-next-line
                  imgElement.src = propertyFallbackImage;
                  // eslint-disable-next-line
                  // imgElement.srcset = propertyFallbackImage;
                }}
                alt={image.title}
                width={288}
                height={192}
                loading={
                  index === 0
                    ? lazilyLoadFirstImage
                      ? 'lazy'
                      : 'eager'
                    : 'lazy'
                }
              />
            );
          })}
        </Box>
      </Box>
      <EmblaPrevButton onClick={onPrevClick} />
      <EmblaNextButton onClick={onNextClick} />
    </Box>
  );
}

export default EmblaImageCarousel;
